import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import querystring from 'query-string';

const RedirectToAuth = ({ children }) => {
  const router = useRouter();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (router.pathname === '/auth') {
      const query = querystring.stringify({ ...router.query, top: 1 });
      const url = `https://${window.location.host}/api/shopify/auth?${query}`;
      window.top.location.href = url;
      // window.open(url, '_blank');
    } else {
      setChecked(true);
    }
  }, [router]);

  if (!checked) return null;

  return children;
};

export default RedirectToAuth;
