import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ME } from '@/query/user';

const useUser = () => {
  const { data, loading, error, refetch } = useQuery(ME);

  const user = data?.me;

  return { user, loading, error, isLoggedIn: user !== undefined };
};

export default useUser;
