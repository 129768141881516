import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Pagination } from '@shopify/polaris';
import useDeepCompareEffect from 'use-deep-compare-effect';

const usePaginatedQuery = (query, options, limit = 50, fixedCount) => {
  const { variables = {}, ...otherOptions } = options;
  const [offset, setOffset] = useState(0);
  const fetchedOffset = useRef(0);
  const result = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset,
      limit,
      ...variables,
    },
    ...otherOptions,
  });

  const count = fixedCount || result.data?.data?.count || 0;

  useEffect(() => {
    if (offset > fetchedOffset.current) {
      result.fetchMore({
        variables: {
          offset,
          limit,
          ...variables,
        },
      });
      fetchedOffset.current = offset;
    }
  }, [offset]);

  useDeepCompareEffect(() => {
    setOffset(0);
  }, [variables]);

  const hasPreviousPage = offset > 0;
  const hasNextPage = offset + limit < count;

  const loadPreviousPage = () => {
    setOffset(offset - limit);
  };

  const loadNextPage = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);

    // if (newOffset >= fetchedCount) {
    //   result.fetchMore({
    //     variables: {
    //       newOffset,
    //       limit: 5,
    //     },
    //   });
    //   setFetchedCount(newOffset + limit);
    // }
  };

  const reset = () => {
    setOffset(0);
    fetchedOffset.current = 0;
  };

  const active = hasPreviousPage || hasNextPage;

  const paginationMarkup = active && (
    <Pagination
      hasPrevious={hasPreviousPage}
      onPrevious={loadPreviousPage}
      hasNext={hasNextPage}
      onNext={loadNextPage}
    />
  );

  return [
    result,
    {
      limit,
      offset,
      setOffset,
      count,
      hasNextPage,
      hasPreviousPage,
      loadNextPage,
      loadPreviousPage,
      reset,
      active,
      paginationMarkup,
    },
  ];
};

export default usePaginatedQuery;
