const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const up = (breakpoint) =>
  `@media (min-width: calc(${size[breakpoint]} + 0.02px))`;

const down = (breakpoint) => `@media (max-width: ${size[breakpoint]})`;

const between = (breakpointMin, breakpointMax) =>
  `@media (min-width: calc(${size[breakpointMin]} + 0.02px) and max-width: ${size[breakpointMax]})`;

export default {
  up,
  down,
  between,
};
