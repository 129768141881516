import { useState, useCallback, useEffect } from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';
import List from './list';

const ProductPicker = ({ value, setValue, limit, exclude = [] }) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(value || []);

  const toggle = useCallback(() => setActive(!active), [active]);

  const handleSave = useCallback(() => {
    if (selected.length > 0) {
      setValue(selected);
    }

    toggle();
  }, [toggle, setValue, selected]);

  const handleCancel = useCallback(() => {
    setSelected(value);
    toggle();
  }, [toggle, setSelected, value]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const activator = (
    <Button onClick={toggle}>
      {selected.length === 0
        ? 'Select products'
        : `${selected.length} product${
            selected.length > 1 ? 's' : ''
          } selected`}
    </Button>
  );

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleCancel}
        title="Select products"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          disabled: selected.length === 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCancel,
          },
        ]}
      >
        <List selected={selected} setSelected={setSelected} limit={limit} />
      </Modal>
    </div>
  );
};

export default ProductPicker;
