import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@/hooks';

const onboardingSteps = [
  // {
  //   step: 'personal-info',
  //   path: ['/onboarding'],
  // },
  {
    step: 'create-offer',
    path: ['/create-first-offer', '/recommendations/quick-setup'],
  },
];

const findCurrentStep = (onboarding) => {
  const { data = {} } = onboarding;

  // if (!data || !data.personalInfo) return 'personal-info';
  if (!data.createFirstOffer) return 'create-offer';

  return [];
};

const RedirectToOnboarding = ({ children }) => {
  const router = useRouter();
  const [isChecked, setIsChecked] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const { user, loading } = useUser();

  console.log('isChecked', isChecked);
  console.log('shouldRedirect', shouldRedirect);

  useEffect(() => {
    function checkOnboarding() {
      if (user && !isChecked) {
        setIsChecked(true);

        const { onboarding } = user || {};

        // handle user who completed onboarding accessing onboarding page
        if (
          onboarding &&
          onboarding.completed &&
          onboardingSteps
            .map((e) => e.path)
            .flat()
            .includes(router.pathname)
        ) {
          console.log('redirect to / in onboarding...');
          console.log(router.pathname);
          router.push('/');
          setShouldRedirect(false);
          return;
        }

        // redirect to right onboarding page
        if (onboarding && !onboarding.completed) {
          const currentStep = findCurrentStep(onboarding);
          const index = onboardingSteps.findIndex(
            (e) => e.step === currentStep
          );

          if (
            onboardingSteps
              .slice(0, index + 1)
              .map((e) => e.path)
              .flat()
              .includes(router.pathname)
          ) {
            return children;
          } else {
            router.push(onboardingSteps[index].path[0]);
            return;
          }
        }

        setShouldRedirect(false);
      }
    }
    checkOnboarding();
  }, [user]);

  if (loading || shouldRedirect) return null;

  return children;
};

export default RedirectToOnboarding;
