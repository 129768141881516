import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'node-fetch';

const httpLink = new HttpLink({
  uri: `${process.env.GRAPHQL_HOST}/graphql`,
  fetch,
});

const userMiddleware = new ApolloLink((operation, forward) => {
  const { user } = operation.getContext();

  // user is passed only when backend needs to get data of a specific user, ie filtering.
  if (user) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        user,
      },
    }));
  }

  return forward(operation);
});

const storeMiddleware = new ApolloLink((operation, forward) => {
  const { store } = operation.getContext();

  const { id, domain } = store || {};

  if (id || domain) {
    const storeHeader = id ? { store: id } : { store_domain: domain };
    operation.setContext(({ headers = {} }) => ({
      headers: Object.assign(headers, storeHeader),
    }));
  }

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error;
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutation: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  ssrMode: true,
  link: from([errorLink, userMiddleware, storeMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
  queryDeduplication: false, // allow multiple calls for the same query
});

export default client;
