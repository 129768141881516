import { useField } from 'formik';
import { BlockStack } from '@shopify/polaris';
import { ProductPicker, ProductList } from '@/components/library';

const Selector = ({
  name,
  exclude,
  isOrderable,
  limit,
  variantsEnabled,
  defaultStateSelection,
}) => {
  const [field, , { setValue }] = useField(`${name}.value`);
  const [variantsField, , variantsHelpers] = useField(`${name}.variants`);
  const [{ value: selected }, , { setValue: setSelected }] = useField(
    `${name}.selected`
  );

  return (
    <BlockStack gap="200">
      <ProductPicker
        value={field.value}
        setValue={setValue}
        exclude={exclude}
        limit={limit}
      />
      <ProductList
        products={field.value}
        setProducts={setValue}
        variants={variantsEnabled ? variantsField.value : null}
        setVariants={variantsEnabled ? variantsHelpers.setValue : null}
        selectionEnabled={defaultStateSelection}
        selected={defaultStateSelection ? selected : null}
        setSelected={defaultStateSelection ? setSelected : null}
        isOrderable={isOrderable}
      />
    </BlockStack>
  );
};

export default Selector;
