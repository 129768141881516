import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { blackA } from '@radix-ui/colors';

const StyledOverlay = styled(DialogPrimitive.Overlay)`
  background: ${blackA.blackA9};
  z-index: 25;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export function Dialog({ children, ...props }) {
  return (
    <DialogPrimitive.Root {...props}>
      <StyledOverlay />
      {children}
    </DialogPrimitive.Root>
  );
}

const StyledContent = styled(DialogPrimitive.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  /* width: 100%; */
  max-width: 90%;
  max-height: 85vh;
  z-index: 50;
  /* margin-top: -5vh; */

  background-color: white;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
`;

// &:focus: {
//     outline: none;
//   }

/* animation: ${fadeIn} 125ms linear, ${moveDown} 125ms cubic-bezier(0.22, 1, 0.36, 1);  */

/* Among other things, prevents text alignment inconsistencies when dialog can't be centered in the viewport evenly.
  Affects animated and non-animated dialogs alike. */
/* willChange: 'transform'; */

/* boxShadow:
    $colors$shadowLight 0px 10px 38px -10px, $colors$shadowDark 0px 10px 20px -15px; */

const StyledCloseButton = styled(DialogPrimitive.Close)`
  position: absolute;
  top: 0;
  right: 0;
`;

/* top: '$2';
  right: '$2', */

export const DialogContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props} ref={forwardedRef}>
        {children}
      </StyledContent>
    </DialogPrimitive.Portal>
  )
);
