import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import AppBridge from './app-bridge';

import RedirectToAuth from '../redirect/auth';

const AppBridgeProvider = ({ children }) => {
  const router = useRouter();
  const [host, setHost] = useState();
  const isEmbedded =
    typeof window !== 'undefined' && window.self !== window.top;
  useEffect(() => {
    const url = new URL(window.location.href);
    const host = url.searchParams.get('host');

    // const { host } = router.query;

    if (host) {
      setHost(host);
    }
  }, []);

  if (!router.isReady) {
    return null;
  }

  if (isEmbedded && !host) {
    return null;
  }

  if (!isEmbedded) {
    return children;
  }

  return (
    <AppBridge>
      <RedirectToAuth>
        {/* <RedirectToSubscription> */}
        {children}
        {/* </RedirectToSubscription> */}
      </RedirectToAuth>
    </AppBridge>
  );
};

export default AppBridgeProvider;
