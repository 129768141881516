import { gql } from '@apollo/client';

export const GET_IMPRESSIONS = gql`
  query GetImpressions($input: AnalyticsInput!) {
    impressions(input: $input)
  }
`;

export const GET_CLICKS = gql`
  query GetClicks($input: AnalyticsInput!) {
    clicks(input: $input)
  }
`;

export const GET_REVENUE = gql`
  query GetRevenue($input: AnalyticsInput!) {
    revenue(input: $input)
  }
`;

export const GET_REVENUE_HISTORY = gql`
  query GetRevenueHistory($input: AnalyticsInput!) {
    revenueHistory(input: $input)
  }
`;

export const OFFER_ANALYTICS = gql`
  query OfferAnalytics($input: AnalyticsInput!) {
    offerAnalytics(input: $input)
  }
`;

export const OFFER_TYPE_ANALYTICS = gql`
  query OfferTypeAnalytics($input: AnalyticsInput!) {
    offerTypeAnalytics(input: $input)
  }
`;

export const PRODUCT_ANALYTICS = gql`
  query ProductAnalytics($input: AnalyticsInput!) {
    productAnalytics(input: $input)
  }
`;
