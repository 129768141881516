import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STORE } from '@/query';

export const useMoney = () => {
  const { loading, data } = useQuery(GET_STORE);

  const { currency } = data?.store || {};

  const format = useCallback(
    (amount) => {
      return amount > -1 && currency
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
          }).format(amount)
        : '';
    },
    [currency]
  );

  return {
    currency,
    format,
  };
};
