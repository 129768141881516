import { useQuery, useApolloClient } from '@apollo/client';
// import { toaster } from 'evergreen-ui';
import { GET_PRODUCT_LIST, GET_STORE } from '@/query';

export default (offer) => {
  const client = useApolloClient();
  const storeResult = useQuery(GET_STORE);
  const { page, type, value } = offer.data.trigger;

  const { domain } = storeResult.data?.store || {};

  const variables = {
    offset: 0,
    limit: 1,
  };

  switch (type) {
    case 'collection':
      variables.collections = value;
      break;
    case 'tag':
      variables.tags = value;
      break;
    default:
      break;
  }

  const redirectToProductPage = (product) => {
    const { onlineStoreUrl, handle } = product.attributes;

    const productUrl = onlineStoreUrl || `https://${domain}/products/${handle}`;

    // add preview as query parameter
    const url = new URL(productUrl);
    url.searchParams.set('preview', offer.id);

    window.open(url, '_blank');
  };

  const preview = () => {
    if (page === 'cart') {
      const url = new URL(`https://${domain}/cart`);
      url.searchParams.set('preview', offer.id);
      window.open(url, '_blank');
    } else if (type === 'product') {
      redirectToProductPage(offer.triggerProducts[0]);
    } else {
      client
        .query({
          query: GET_PRODUCT_LIST,
          variables,
        })
        .then((result) => {
          const [product] = result.data.products;
          if (!product) {
            // toaster.danger('Product not found');
            return;
          }
          redirectToProductPage(product);
        });
    }
  };

  return {
    preview,
    // loading,
  };
};
