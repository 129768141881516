import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  BlockStack,
  ResourceList,
  ResourceItem,
  Text,
  LegacyFilters,
} from '@shopify/polaris';
import { COLLECTIONS } from '@/query';

const List = ({ selected, setSelected }) => {
  const { data, loading } = useQuery(COLLECTIONS);
  const [query, setQuery] = useState(null);

  const handleQueryChange = useCallback((value) => {
    setQuery(value);
  }, []);
  const handleQueryRemove = useCallback(() => setQuery(null), []);

  const collections =
    data?.collections?.map((e) => ({
      id: e.shopifyId,
      title: e.data.title,
    })) || [];

  const resourceName = {
    singular: 'collection',
    plural: 'collections',
  };

  const filterControl = (
    <LegacyFilters
      queryValue={query}
      filters={[]}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
    />
  );

  const regex = new RegExp(query, 'i');

  const renderItem = useCallback((item) => {
    return (
      <ResourceItem id={item.id}>
        <Text variant="bodyMd" as="p" fontWeight="semibold">
          {item.title}
        </Text>
      </ResourceItem>
    );
  }, []);

  return (
    <BlockStack>
      <ResourceList
        resourceName={resourceName}
        items={
          query ? collections.filter((e) => e.title.match(regex)) : collections
        }
        renderItem={renderItem}
        selectedItems={selected}
        onSelectionChange={setSelected}
        filterControl={filterControl}
        selectable
        loading={loading}
      />
    </BlockStack>
  );
};

export default List;
