import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetProducts($offset: Int, $limit: Int) {
    products(offset: $offset, limit: $limit) {
      id
      shopifyId
      isActive
      data
      categories {
        name
        path
      }
    }
  }
`;

export const GET_PRODUCT_LIST = gql`
  query GetProductList(
    $offset: Int
    $limit: Int
    $query: String
    $ids: [ID]
    $collections: [ID]
    $tags: [String]
  ) {
    products(
      offset: $offset
      limit: $limit
      query: $query
      ids: $ids
      collections: $collections
      tags: $tags
    ) {
      id
      variants {
        data
      }
      attributes {
        title
        image
        handle
        onlineStoreUrl
      }
    }
  }
`;

export const GET_PRODUCT_VARIANTS = gql`
  query GetProductVariants($id: ID!) {
    product(filter: { id: $id }) {
      variants {
        id
        attributes {
          title
          price
          image
        }
      }
    }
  }
`;

export const PRODUCT_COUNT = gql`
  query ProductCount($query: String) {
    productCount(query: $query)
  }
`;

export const PRODUCT_UPDATE = gql`
  mutation ProductUpdate($input: ProductUpdateInput!) {
    productUpdate(input: $input) {
      id
      isActive
    }
  }
`;

export const PRODUCT_BULK_UPDATE = gql`
  mutation ProductBulkUpdate(
    $ids: ProductBulkUpdateIds!
    $input: ProductBulkUpdateInput!
  ) {
    productBulkUpdate(ids: $ids, input: $input) {
      id
      isActive
    }
  }
`;

export const PRODUCT_UPDATE_ALL = gql`
  mutation ProductUpdateAll($input: ProductBulkUpdateInput!) {
    productUpdateAll(input: $input) {
      id
      isActive
    }
  }
`;

export const SET_PRODUCT_CATEGORY = gql`
  mutation SetProductCategory($productIds: [ID!], $categoryIds: [ID!]) {
    setProductCategory(productIds: $productIds, categoryIds: $categoryIds)
  }
`;

export const CLEAR_PRODUCT_CATEGORY = gql`
  mutation ClearProductCategory($productIds: [ID!]) {
    clearProductCategory(productIds: $productIds)
  }
`;

export const COLLECTIONS = gql`
  query Collections {
    collections {
      shopifyId
      data
    }
  }
`;

export const PRODUCT_TAGS = gql`
  query ProductTags {
    productTags
  }
`;
