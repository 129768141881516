import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import Hotjar from '@hotjar/browser';
import { useUser, useSupport } from '@/hooks';
import { GET_STORE } from '@/query';

const UserContext = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const { user, loading } = useUser();
  const storeResult = useQuery(GET_STORE);
  const { isChatEnabled } = useSupport();

  const router = useRouter();

  const { store } = storeResult.data || {};

  useEffect(() => {
    if (initialized || !user || !store) return;

    const identifyUser = () => {
      if (user.trackingDisabled || process.env.NODE_ENV !== 'production')
        return;

      const { id, fullName, email } = user;

      setTimeout(() => {
        if (isChatEnabled) {
          window.Atlas.chat?.showBubble();
        }
      }, 2000);

      window.Atlas.call('identify', {
        userId: id,
        name: fullName,
        customFields: {
          myshopify_domain: store.domain,
          store_id: store.id,
        },
        // email, // this would override the manually overriden email in the platform
      });

      // record session only after app install
      const hoursSinceRegistered =
        (new Date().getTime() - new Date(user.createdAt).getTime()) / 3600000;

      if (hoursSinceRegistered < 24) {
        const hotjarVersion = 6;
        Hotjar.init(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID, hotjarVersion);
        Hotjar.identify(id, {
          user_id: id,
          name: fullName,
          created_at: user.createdAt,
          store_id: store.id,
          domain: store.domain,
          plan: store.data.plan_name,
        });
      }
    };

    identifyUser();
    setInitialized(true);
  }, [user, store]);

  return children;
};

export default UserContext;
