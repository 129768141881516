import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const useNavigationTabs = (tabs) => {
  const router = useRouter();
  const { asPath } = router;
  const findIndex = () => {
    const index = tabs.findIndex((e) => e.path === asPath);
    return index === -1 ? 0 : index;
  };

  const [selectedIndex, setSelectedIndex] = useState(findIndex());

  useEffect(() => {
    setSelectedIndex(findIndex());
  }, [asPath]);

  return { selectedIndex };
};

export default useNavigationTabs;
