import { ColorPicker, Popover, Button } from '@shopify/polaris';
import { useState, useEffect, useCallback } from 'react';
import convert from 'color-convert';
import { useField } from 'formik';
import { TextInputField } from '../text-input';

const isValidHex = (color) => {
  return /(^#[0-9A-F]{6}$)/i.test(color);
};

const ColorPickerField = ({ name, label = 'Color' }) => {
  const [field, meta, helpers] = useField(name);
  const [popoverActive, setPopoverActive] = useState(false);
  const [color, setColor] = useState();

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  useEffect(() => {
    if (!color) return;
    const hex = `#${convert.hsl.hex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100
    )}`;
    if (hex !== field.value) {
      helpers.setValue(hex);
    }
  }, [color]);

  const handleInputChange = useCallback((value) => {
    if (value && isValidHex(value)) {
      const [h, s, l] = convert.hex.hsl(value);
      setColor({
        hue: h,
        saturation: s / 100,
        brightness: l / 100,
      });
    }
  }, []);

  const activator = (
    <div
      role="button"
      onClick={togglePopoverActive}
      style={{ position: 'relative' }}
    >
      <TextInputField name={name} label={label} onChange={handleInputChange} />
      <div
        style={{
          backgroundColor: field.value,
          position: 'absolute',
          bottom: '6px',
          right: '8px',
          width: '20px',
          height: '20px',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 200,
          // boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 10%)',
          border:
            'var(--p-border-width-0165) solid var(--p-color-input-border)',
        }}
      />
    </div>
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        autofocusTarget="none"
        onClose={togglePopoverActive}
      >
        <ColorPicker
          onChange={setColor}
          color={
            color || {
              hue: 120,
              brightness: 1,
              saturation: 1,
            }
          }
        />
      </Popover>
    </div>
  );
};

export default ColorPickerField;
