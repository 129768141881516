import styled from 'styled-components';
import * as SliderPrimitive from '@radix-ui/react-slider';

const StyledSlider = styled(SliderPrimitive.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: ${(props) => props.width ?? '100%'};

  &[data-orientation='horizontal'] {
    height: 20px;
  }

  &[data-orientation='vertical'] {
    flex-direction: column;
    width: 20px;
    height: 100px;
  }
`;

const StyledTrack = styled(SliderPrimitive.Track)`
  background-color: ${({ theme }) => theme.colors.gray400};
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;

  &[data-orientation='horizontal'] {
    height: 3px;
  }
  &[data-orientation='vertical'] {
    width: 3px;
  }
`;

const StyledRange = styled(SliderPrimitive.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.selected};
  border-radius: 9999px;
  height: 100%;
`;

const StyledThumb = styled(SliderPrimitive.Thumb)`
  all: unset;
  display: block;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.selected};
  box-shadow: 0 2px 10px ${({ theme }) => theme.colors.gray400};
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.selected};
  }
  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.blue100};
  }
`;

const Slider = (props) => (
  <StyledSlider {...props}>
    <StyledTrack>
      <StyledRange />
    </StyledTrack>
    <StyledThumb />
  </StyledSlider>
);

export default Slider;
