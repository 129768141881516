import { Box, Text, Divider, Bleed, InlineStack } from '@shopify/polaris';

export const CardSection = ({ title, actions, divider = true, children }) => {
  return (
    <Box>
      {divider ? (
        <Bleed marginInline="400">
          <Box paddingBlockStart="200">
            <Divider />
          </Box>
        </Bleed>
      ) : null}
      <Box>
        <InlineStack align="space-between">
          {title && (
            <Box paddingBlockStart="200">
              <Text as="p" variant="bodySm" fontWeight="medium" tone="subdued">
                {title.toUpperCase()}
              </Text>
            </Box>
          )}
          {actions}
        </InlineStack>
        <Box paddingBlockStart="400" paddingBlockEnd="400">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
