import { useCallback } from 'react';
import { useField } from 'formik';
import { ChoiceList } from '@shopify/polaris';

const Field = ({ name, onChange, ...props }) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  const handleChange = useCallback(
    (value) => {
      if (onChange) onChange();
      setValue(value[0]);
      setTouched(true, false);
    },
    [setValue]
  );

  return (
    <ChoiceList selected={[field.value]} onChange={handleChange} {...props} />
  );
};

export default Field;
