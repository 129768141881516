import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Button, FormLayout } from '@shopify/polaris';
import { CircleMinusMinor } from '@shopify/polaris-icons';
import { ChoiceListField } from '../radio';
import { CardSection } from '../card-section';
import ProductSelector from './product';
import CollectionSelector from './collection';
import TagSelector from './tag';

const options = [
  {
    label: 'Specific products',
    value: 'product',
  },
  {
    label: 'Collections',
    value: 'collection',
  },
  {
    label: 'Tags',
    value: 'tag',
  },
  {
    label: 'All products',
    value: 'all',
  },
  {
    label: 'AI recommendations',
    value: 'rec',
  },
];

export const ProductCondition = ({
  name,
  exclude,
  showExclude = false,
  types = [],
  isOrderable,
  limit,
  variantsEnabled = false,
  defaultStateSelection = false,
}) => {
  const [typeField, typeMeta] = useField(`${name}.type`);
  const [valueField, meta, valueHelpers] = useField(`${name}.value`);

  useEffect(() => {
    if (!valueField.value) valueHelpers.setValue([]);
  }, []);

  useEffect(() => {
    if (typeMeta.touched) {
      valueHelpers.setValue([]);
    }
  }, [typeField.value]);

  const choices =
    types.length > 0
      ? options.filter((option) => types.includes(option.value))
      : options;

  const handleTypeChange = () => {
    valueHelpers.setValue([]);
  };

  return (
    <FormLayout>
      {types.length !== 1 && (
        <ChoiceListField
          name={typeField.name}
          choices={choices}
          onChange={handleTypeChange}
        />
      )}
      {typeField.value === 'product' && (
        <ProductSelector
          name={name}
          exclude={exclude}
          isOrderable={isOrderable}
          limit={limit}
          variantsEnabled={variantsEnabled}
          defaultStateSelection={defaultStateSelection}
        />
      )}
      {typeField.value === 'collection' && (
        <CollectionSelector name={valueField.name} />
      )}
      {typeField.value === 'tag' && <TagSelector name={valueField.name} />}
      {showExclude && typeField.value !== 'product' && (
        <ExcludeProducts name={`${name}.exclude`} exclude={exclude} />
      )}
    </FormLayout>
  );
};

const ExcludeProducts = ({ name }) => {
  const [{ value }] = useField(name);
  const [isExpanded, setIsExpanded] = useState(value && value.value.length > 0);

  if (!isExpanded) {
    return (
      <Button
        variant="tertiary"
        icon={CircleMinusMinor}
        onClick={() => setIsExpanded(true)}
        accessibilityLabel="Collapse"
      >
        Exclude products
      </Button>
    );
  }

  return (
    <CardSection title="Exclude products">
      <ProductCondition
        name={name}
        types={['product', 'collection', 'tag']}
        variantsEnabled
      />
    </CardSection>
  );
};
