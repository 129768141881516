import * as Switch from '@radix-ui/react-switch';
import { useField } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

const StyledRoot = styled(Switch.Root)`
  display: inline-flex;
  height: 1.6rem;
  width: 3rem;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &[data-state='checked'] {
    background-color: #4a4a4a;
  }
  &[data-state='unchecked'] {
    background-color: #e3e3e3;
  }
  padding: 0rem 0.6rem;
`;

const StyledThumb = styled(Switch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--black-a7);
  transition: transform 100ms;
  will-change: transform;
  transform: translateX(-0.6rem);
  &[data-state='checked'] {
    transform: translateX(14px);
  }
`;

const SwitchField = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;
  const [isChecked, setIschecked] = useState(
    value !== undefined ? value : true
  );
  const onChange = () => {
    setIschecked(!isChecked);
    setValue(!isChecked);
  };
  return (
    <StyledRoot checked={isChecked} onClick={onChange}>
      <StyledThumb />
    </StyledRoot>
  );
};

export default SwitchField;
