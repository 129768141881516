import { gql } from '@apollo/client';

export const RESELLER_ORDERS = gql`
  query ResellerOrders {
    resellerOrders {
      id
      name
      createdAt
      email
      customer {
        name
      }
      shippingAddress {
        name
      }
      charge {
        status
      }
      fulfillmentStatus
    }
  }
`;

export const SUPPLIER_ORDERS = gql`
  query SupplierOrders {
    supplierOrders {
      id
      name
      createdAt
      transfer {
        id
        amount
      }
    }
  }
`;
