import { gql } from '@apollo/client';
import client from '@/lib/apollo-node';

export const GET_CUSTOMER_ACCOUNT = gql`
  query GetCustomerAccount($filter: CustomerAccountFilter) {
    customerAccount(filter: $filter) {
      id
      customerId
    }
  }
`;

export const GET_CONNECTED_ACCOUNT = gql`
  query GetConnectedAccount($filter: ConnectedAccountFilter) {
    connectedAccount(filter: $filter) {
      id
      accountId
      isVerified
    }
  }
`;

export const ACCOUNT_BALANCE = gql`
  query AccountBalance {
    connectedAccount {
      totalPayoutLast30
      balance {
        available
        instantAvailable
        pending
        reserved
      }
    }
  }
`;

export const ACCOUNT_REQUIREMENTS = gql`
  query ConnectedAccountRequirements($filter: ConnectedAccountFilter) {
    connectedAccount(filter: $filter) {
      id
      requirements
      isVerified
    }
  }
`;

export const CREATE_CUSTOMER_ACCOUNT = gql`
  mutation createCustomerAccount {
    customerAccount: createCustomerAccount {
      id
      customerId
    }
  }
`;

export const CREATE_CONNECTED_ACCOUNT = gql`
  mutation createConnectedAccount($ip: String!) {
    connectedAccount: createConnectedAccount(ip: $ip) {
      id
    }
  }
`;
