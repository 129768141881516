import { useState, useCallback, useEffect } from 'react';
import { BlockStack, Button, Modal, Tag, InlineStack } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useQuery } from '@apollo/client';
import { PRODUCT_TAGS } from '@/query';
import List from './list';

const CollectionPicker = ({ value, setValue }) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(value || []);

  const { data, loading, error } = useQuery(PRODUCT_TAGS);

  const tags = data?.productTags || [];
  const selectedTags = tags.filter((tag) => {
    return selected.includes(tag);
  });

  const tagsListMarkup = selectedTags.map((tag) => (
    <Tag
      onRemove={() => {
        setSelected(selected.filter((t) => t !== tag));
        setValue(selected.filter((t) => t !== tag));
      }}
      key={tag}
    >
      {tag}
    </Tag>
  ));

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleSave = useCallback(() => {
    if (selected.length > 0) {
      setValue(selected);
    }

    handleChange();
  }, [handleChange, setValue, selected]);

  const activator = (
    <BlockStack gap="200">
      <InlineStack gap="200">
        <Button onClick={handleChange}>
          {selected.length === 0
            ? 'Select tags'
            : `${selected.length} tag${
                selected.length > 1 ? 's' : ''
              } selected`}
        </Button>
      </InlineStack>
      <InlineStack gap="200">{tagsListMarkup}</InlineStack>
    </BlockStack>
  );
  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Select tags"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          disabled: isEqual(selected, value),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <List selected={selected} setSelected={setSelected} data={data} />
      </Modal>
    </div>
  );
};

export default CollectionPicker;
