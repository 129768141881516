import { useCallback } from 'react';
import { useField } from 'formik';
import { SettingToggle } from '@shopify/polaris';

const CheckboxField = ({ name, content, label }) => {
  const [{ value }, _, { setValue }] = useField(name);

  const handleToggle = useCallback(() => setValue(!value), [value]);

  return (
    <SettingToggle
      enabled={value}
      action={{
        content: content(value),
        onAction: handleToggle,
      }}
    >
      {label(value)}
    </SettingToggle>
  );
};

export default CheckboxField;
