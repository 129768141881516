import styled from 'styled-components';

export { default as PageLayout } from './page-layout';
export { default as PageSpinner } from './spinner';

export const Flex = styled.div`
  display: flex;
  ${(props) => `
    ${props.column ? 'flex-direction: column;' : ''}
    ${props.wrap ? `flex-wrap: ${props.wrap};` : ''}
    ${props.justify ? `justify-content: ${props.justify};` : ''}
    ${props.align ? `align-items: ${props.align};` : ''}
    ${props.grow ? 'flex-grow: 1;' : ''}
    ${props.gap ? `gap: ${props.gap}px;` : ''}
    ${props.noshrink ? 'flex-shrink: 0;' : ''}
  `}
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(auto, auto);
`;

export const Narrow = styled.div`
  /* flex-grow: 1;
  max-width: 960px; */
`;
