import { useField } from 'formik';
import { CollectionPicker } from '@/components/library';

const Selector = ({ name }) => {
  const [field, meta, { setValue }] = useField(name);

  return <CollectionPicker value={field.value} setValue={setValue} />;
};

export default Selector;
