// import baseTheme from './evergreen';
import breakpoints from './breakpoints';

const layout = {
  padding: '24px',
  narrow: '960px',
};

export default {
  // ...baseTheme,
  layout,
  breakpoints,
};
