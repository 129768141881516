import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  LegacyCard,
  ResourceList,
  Avatar,
  ResourceItem,
  Text,
  BlockStack,
} from '@shopify/polaris';
import { GET_PRODUCT_VARIANTS } from '@/query';

const VariantList = ({ product, selected, setSelected }) => {
  const { data, loading } = useQuery(GET_PRODUCT_VARIANTS, {
    variables: {
      id: product.id,
    },
  });

  const { variants = [] } = data?.product || {};

  const resourceName = {
    singular: 'variant',
    plural: 'variants',
  };

  const handleChange = useCallback(
    (value) => setSelected(value.length === variants.length ? 'all' : value),
    [setSelected, variants]
  );

  const renderItem = useCallback((item) => {
    const { title } = item.attributes;

    return (
      <ResourceItem id={item.id}>
        <Text variant="bodyMd" as="p" fontWeight="semibold">
          {title}
        </Text>
      </ResourceItem>
    );
  }, []);

  return (
    <BlockStack>
      <ResourceList
        resourceName={resourceName}
        items={variants}
        renderItem={renderItem}
        selectedItems={
          selected === 'all' ? variants.map((e) => e.id) : selected
        }
        onSelectionChange={handleChange}
        selectable
        loading={loading}
      />
    </BlockStack>
  );
};

export default VariantList;
