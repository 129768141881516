import { gql } from '@apollo/client';

export const GET_TOP_LEVEL_CATEGORIES = gql`
  query GetTopLevelCategories {
    categories {
      id
      name
    }
  }
`;

export const GET_CHILD_CATEGORIES = gql`
  query getCategory($id: ID!) {
    category(id: $id) {
      name
      children {
        id
        name
      }
    }
  }
`;
