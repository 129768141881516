import { useState, useCallback } from 'react';
import { Button, Modal } from '@shopify/polaris';
import List from './list';

const VariantPicker = ({ product, value, setValue }) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(value || 'all');

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleSave = useCallback(() => {
    if (selected.length > 0) {
      setValue(selected);
    }

    handleChange();
  }, [handleChange, setValue, selected]);

  const activator = (
    <Button onClick={handleChange} variant="plain">
      Edit
    </Button>
  );

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Select variants"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          disabled: selected.length === 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <List product={product} selected={selected} setSelected={setSelected} />
      </Modal>
    </div>
  );
};

export default VariantPicker;
