import { useState, useCallback } from 'react';
import { Stack, Button, Modal } from '@shopify/polaris';
import List from './list';

const TextPicker = ({ title, items }) => {
  console.log(items);
  const [active, setActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = <Button onClick={handleChange}>Open</Button>;

  return (
    <Modal
      activator={activator}
      open={active}
      title="Select collections"
      onClose={handleChange}
    >
      <List items={items} />
    </Modal>
  );
};

export default TextPicker;
