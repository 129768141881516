import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import { useAppBridge } from '@shopify/app-bridge-react';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

const createCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: {
            ...offsetLimitPagination(['query']),
            read(existing, { args: { offset, limit } }) {
              return existing && existing.slice(offset, offset + limit);
            },
          },
        },
      },
    },
  });
};

const shopifyAppBridgeAuthMiddleware = setContext(async (_, { headers }) => {
  // check if Shopify App Bridge instance exists
  const isEmbedded =
    typeof window !== 'undefined' && window.self !== window.top;
  if (!isEmbedded) return {};

  const shopify = useAppBridge();
  const token = await shopify.idToken();

  if (!token) {
    console.log(`Can't find session token`); // eslint-disable-line
    return {};
  }

  return {
    headers: {
      ...headers,
      'x-session-token': token,
    },
  };
});

const storeMiddleware = new ApolloLink((operation, forward) => {
  // server side only
  if (typeof window !== 'undefined') return forward(operation);

  const { store } = operation.getContext();

  const { id, domain } = store || {};

  if (id || domain) {
    const storeHeader = id ? { store: id } : { store_domain: domain };
    operation.setContext(({ headers = {} }) => ({
      headers: Object.assign(headers, storeHeader),
    }));
  }

  return forward(operation);
});

const createApolloClient = () => {
  const isServer = typeof window === 'undefined';

  const ourGraphqlLink = new HttpLink({
    uri: isServer ? `${process.env.GRAPHQL_HOST}/graphql` : '/graphql',
    credentials: 'include',
    // credentials: 'same-origin',
  });
  const shopifyGraphqlLink = new HttpLink({
    uri: '/api/shopify/graphql',
    credentials: 'include',
    // credentials: 'same-origin',
  });

  const httpLink = split(
    (operation) => operation.getContext().clientName === 'shopify',
    shopifyGraphqlLink,
    ourGraphqlLink
  );

  const params = {
    ssrMode: isServer,
    link: from([shopifyAppBridgeAuthMiddleware, storeMiddleware, httpLink]),
    cache: createCache(),
    fetchOptions: {
      // mode: 'no-cors',
    },
    connectToDevTools: process.env.NODE_ENV === 'development',
  };

  return new ApolloClient(params);
};

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = (client, pageProps) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = (pageProps) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
};

export const clearCache = () => {
  if (apolloClient) apolloClient.cache.reset();
};
