import { useState, useCallback, createContext, useContext } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LangProvider = ({ children }) => {
  const [language, setLanguage] = useState('default');

  const changeLanguage = useCallback((lang) => {
    setLanguage(lang);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LangProvider;
