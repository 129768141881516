import {
  useState,
  useCallback,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { Toast } from '@shopify/polaris';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const show = useCallback(
    (toast) => {
      setToasts((ts) => {
        const id = ts.reduce((max, t) => Math.max(t.id, max), 0) + 1;
        const Component = (
          <Toast
            key={id}
            duration={3000}
            {...toast}
            onDismiss={() => setToasts((tss) => tss.filter((t) => t.id !== id))}
          />
        );

        return [
          ...ts,
          {
            id,
            Component,
          },
        ];
      });
    },
    [setToasts]
  );

  const Toasts = useMemo(() => {
    return toasts.map(({ id, Component }) => Component);
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ show }}>
      {children}
      {Toasts}
    </ToastContext.Provider>
  );
};

export const useToaster = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context.show;
};
