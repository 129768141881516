import { Spinner, Page, InlineStack } from '@shopify/polaris';

const PageSpinner = () => (
  <Page>
    <InlineStack align="center">
      <Spinner size="large" />
    </InlineStack>
  </Page>
);

export default PageSpinner;
