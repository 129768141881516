import { useState, useEffect } from 'react';
import useStore from './store';

const plans = {
  legacy: 0,
  basic: 1,
  pro: 2,
  growth: 3,
  flex: 4,
};

export const usePlan = (required) => {
  const [allowed, setAllowed] = useState(!required);
  const { store } = useStore();
  const { plan, period } = store?.appInstallation || {};

  useEffect(() => {
    if (required && plan) {
      setAllowed(plans[plan] >= plans[required]);
    }
  }, [required, plan]);

  return { plan, period, allowed };
};
