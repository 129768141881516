import { gql } from '@apollo/client';

const OnboardingFragment = gql`
  fragment OnboardingFragment on Onboarding {
    completed
    step
    data
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      fullName
      onboarding {
        completed
      }
      trackingDisabled
      createdAt
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    user {
      id
      email
      fullName
      firstName
      lastName
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput) {
    user: createUser(input: $input) {
      id
      email
      fullName
      firstName
      lastName
    }
  }
`;

export const ASSOCIATE_USER_STORE = gql`
  mutation addStoreUser($input: StoreUserInput!) {
    addStoreUser(input: $input)
  }
`;

export const ONBOARDING = gql`
  query Onboarding {
    me {
      id
      onboarding {
        ...OnboardingFragment
      }
    }
  }
  ${OnboardingFragment}
`;

export const ONBOARDING_UPDATE = gql`
  mutation OnboardingUpdate($input: JSON!) {
    onboardingUpdate(input: $input) {
      id
      onboarding {
        ...OnboardingFragment
      }
    }
  }
  ${OnboardingFragment}
`;
