import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useUser } from '@/hooks';
import { GET_STORE } from '@/query';

const RedirectToShopifyAdmin = ({ children }) => {
  const router = useRouter();
  const { user } = useUser();
  const { data, loading, error } = useQuery(GET_STORE);
  const [shouldRedirect, setShouldRedirect] = useState(
    typeof window !== 'undefined' && window.self === window.top
  );

  useEffect(() => {
    if (user && data?.store && shouldRedirect) {
      // allow non-embeded for admin sessions
      if (user.trackingDisabled) {
        setShouldRedirect(false);
        return;
      }

      const appPath =
        process.env.NODE_ENV === 'development' ? 'assortion-dev' : 'assortion';

      window.location.href = `https://${data.store.domain}/admin/apps/${appPath}`;
    }
  }, [user, data]);

  if (shouldRedirect) {
    return null;
  }

  return children;
};

export default RedirectToShopifyAdmin;
