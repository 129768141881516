import styled from 'styled-components';
import { Frame } from '@shopify/polaris';
import NavBar from '@/components/nav';
import { ToastProvider } from '@/context/toast';

// check auth

const PageLayout = ({ children }) => {
  const topBarMarkup = (
    <div
      style={{
        // 'box-shadow': 'var(--p-shadow-top-bar)',
        backgroundColor: 'var(--p-background)',
      }}
    >
      <NavBar />
    </div>
  );

  return (
    <Frame topBar={topBarMarkup}>
      <ToastProvider>
        <Padding>{children}</Padding>
      </ToastProvider>
    </Frame>
  );
};

const Padding = styled.div`
  margin-bottom: 84px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PageContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.layout.padding};
  overflow-y: scroll;
`;

export default PageLayout;
