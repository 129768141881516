import { gql } from '@apollo/client';

const PAYMENT_METHOD_FIELDS = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    isDefault
    data {
      __typename
      ... on LegacyCard {
        last4
        expMonth
        expYear
        brand
        funding
      }
      ... on BankAccount {
        bankName
        last4
        currency
      }
    }
  }
`;

const PAYOUT_METHOD_FIELDS = gql`
  fragment PayoutMethodFields on PayoutMethod {
    id
    isDefault
    data {
      __typename
      ... on LegacyCard {
        last4
        expMonth
        expYear
        brand
        funding
      }
      ... on BankAccount {
        bankName
        last4
        currency
      }
    }
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation PaymentMethodCreate($input: PaymentMethodInput!) {
    paymentMethodCreate(input: $input) {
      ...PaymentMethodFields
    }
  }
  ${PAYMENT_METHOD_FIELDS}
`;

export const CREATE_PAYOUT_METHOD = gql`
  mutation PayoutMethodCreate($input: PayoutMethodInput!) {
    payoutMethodCreate(input: $input) {
      ...PayoutMethodFields
    }
  }
  ${PAYOUT_METHOD_FIELDS}
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation PaymentMethodDelete($id: ID!) {
    paymentMethodDelete(id: $id) {
      id
    }
  }
`;

export const DELETE_PAYOUT_METHOD = gql`
  mutation PayoutMethodDelete($id: ID!) {
    payoutMethodDelete(id: $id) {
      id
    }
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation PaymentMethodSetDefault($id: ID!) {
    paymentMethodSetDefault(id: $id) {
      ...PaymentMethodFields
    }
  }
  ${PAYMENT_METHOD_FIELDS}
`;

export const SET_DEFAULT_PAYOUT_METHOD = gql`
  mutation PayoutMethodSetDefault($id: ID!) {
    payoutMethodSetDefault(id: $id) {
      ...PayoutMethodFields
    }
  }
  ${PAYOUT_METHOD_FIELDS}
`;

export const GET_PAYMENT_METHODS = gql`
  query PaymentMethods {
    paymentMethods {
      ...PaymentMethodFields
    }
  }
  ${PAYMENT_METHOD_FIELDS}
`;

export const GET_PAYOUT_METHODS = gql`
  query PayoutMethods {
    payoutMethods {
      ...PayoutMethodFields
    }
  }
  ${PAYOUT_METHOD_FIELDS}
`;

export const CHARGES = gql`
  query Charges {
    charges {
      id
      created
      amount
      status
      paymentMethod {
        ...PaymentMethodFields
      }
    }
  }
  ${PAYMENT_METHOD_FIELDS}
`;

export const PAYOUTS = gql`
  query Payouts {
    payouts {
      id
      amount
      created
      arrivalDate
      status
      failureCode
      failureMessage
      payoutMethod {
        ...PayoutMethodFields
      }
    }
  }
  ${PAYOUT_METHOD_FIELDS}
`;
