import axios from '@/lib/axios';

export const onFileChange = async (file, setError, setLoading, setData) => {
  if (!file || !file.type.startsWith('image/')) {
    setError({ content: 'Please use file that image format', error: true });
    return;
  }
  setLoading(true);
  const result = await axios
    .post(
      '/api/shopify/file-upload',
      {
        filename: file?.name,
        mimeType: file?.type,
        fileSize: file?.size.toString(),
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .catch(() => {
      setLoading(false);
      setError({ content: 'Failed to fetch file', error: true });
    });
  const { url, parameters } =
    result?.data?.data?.stagedUploadsCreate?.stagedTargets[0] || {};
  const formData = new FormData();

  parameters?.forEach((param) => {
    formData.append(param.name, param.value);
  });

  formData.append('file', file);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  }).catch(() => {
    setLoading(false);
    setError({ content: 'Failed to upload file', error: true });
  });

  if (response.ok) {
    const key = parameters?.find((p) => p.name === 'key');
    const res = await axios
      .post('/api/shopify/file-create', {
        alt: '',
        contentType: 'IMAGE',
        duplicateResolutionMode: 'APPEND_UUID',
        filename: file?.name,
        originalSource: `${url}${key?.value}`,
      })
      .catch(() => {
        setLoading(false);
        setError({ content: 'Failed to create file', error: true });
      });

    setData({
      ...res.data.image,
      ...{
        name: file?.name,
        size: file?.size.toString(),
      },
    });
  }
  setLoading(false);
};
