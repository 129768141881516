import { gql } from '@apollo/client';

export const JOB = gql`
  query Job($id: ID!, $queue: String!) {
    job(id: $id, queue: $queue) {
      id
      state
    }
  }
`;

export const BULK_OPERATION = gql`
  query BulkOperation {
    bulkOperation
  }
`;

export const JOB_CREATE = gql`
  mutation JobCreate($input: JobInput!) {
    job: jobCreate(input: $input) {
      id
      state
    }
  }
`;
