import { useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; // other styles are also available

export const CodeEditor = ({ name, language = 'css', placeholder }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <StyledWrapper>
      <Editor
        value={field.value || ''}
        onValueChange={(code) => helpers.setValue(code)}
        highlight={(code) => highlight(code, languages[language])}
        padding={10}
        style={{
          // fontFamily: fontFamilies.ui,
          fontSize: 13,
          lineHeight: '20px',
        }}
        placeholder={placeholder || 'Write your custom css here...'}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  border-radius: 4px;

  textarea {
    border: 1px solid rgb(193, 200, 205) !important;
    border-radius: 4px !important;
    min-height: 120px;

    &:focus-visible {
      outline: none;
      border-color: rgb(198, 212, 249) !important;
      box-shadow: rgb(214 224 255) 0px 0px 0px 2px !important;
      z-index: 2 !important;
    }
  }

  pre {
    min-height: 120px;
  }

  div {
    overflow: visible !important;
  }
`;
