import { RangeSlider } from '@shopify/polaris';
import { useField } from 'formik';

const SliderField = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <RangeSlider
      value={field.value}
      onChange={(val) => helpers.setValue(val)}
      suffix={<p>{field.value}</p>}
      {...props}
    />
  );
};

export default SliderField;
