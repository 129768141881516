import { useState, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Link from 'next/link';
import { ApolloProvider } from '@apollo/client';
import { Inter } from 'next/font/google';
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { useApollo } from '@/lib/apollo';
import * as snippet from '@segment/snippet';
import * as Sentry from '@sentry/react';
import styledTheme from '@/components/theme/styled';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppProvider } from '@shopify/polaris';
import AppBridgeProvider from '@/components/app-bridge';
import { UserContext } from '@/components/user';
import { PageLayout } from '@/components/library';
import Redirect from '@/components/redirect';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ShopifyVizProvider } from '@/components/shopify-viz';
import '@shopify/polaris/build/esm/styles.css';
import '@shopify/polaris-viz/build/esm/styles.css';
import '@shopify/polaris-tokens/css/styles.css';
import LangProvider from '@/context/language';
import { AtlasSnippet } from '../util/atlas-snippet';

const GlobalStyle = createGlobalStyle`
:root {
  --p-background: #F1F1F1;
}
html, body {
  /*
  background-color: #f5f6f7;
  font-family: "Inter", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 400; 
  */
}

a {
  text-decoration: none;
  color: inherit;
}

.Polaris-LegacyCard + .Polaris-LegacyCard {
  margin-top: var(--p-space-0) !important;
}

.Polaris-Frame__TopBar {
  height: 2.5rem;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 2.5rem;
}

.Polaris-Frame-ContextualSaveBar {
      height: 3rem;
}

.widget-preview {
  visibility: hidden;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .widget-preview {
  visibility: visible;
}



`;

function renderAnalyticsSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || DEFAULT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    // page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

const inter = Inter({ subsets: ['latin'] });

const LinkWrapper = ({ children, url, external, ...rest }) => {
  if (external) {
    return (
      <a href={url} target="_blank" {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link href={url} {...rest}>
      {children}
    </Link>
  );
};

const App = ({ Component, pageProps = {}, host, apiKey, err }) => {
  const apolloClient = useApollo(pageProps);
  const [isClient, setIsClient] = useState(false);

  // prevents nextjs hydration mismatch
  // https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <Script
          id="segment-script"
          dangerouslySetInnerHTML={{ __html: renderAnalyticsSnippet() }}
        />
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
      </Head>
      <AtlasSnippet />
      <AppProvider i18n={enTranslations} linkComponent={LinkWrapper}>
        <ShopifyVizProvider>
          <ApolloProvider client={apolloClient}>
            <AppBridgeProvider>
              <StyledThemeProvider theme={styledTheme}>
                <ui-nav-menu>
                  <Link href="/" rel="home">
                    Home
                  </Link>
                  <Link href="/cart" prefetch>
                    Upsell cart
                  </Link>
                  <Link href="/analytics">Analytics</Link>
                  <Link href="/settings">Settings</Link>
                  <Link href="/plan">Plan</Link>
                </ui-nav-menu>
                <GlobalStyle />
                <Sentry.ErrorBoundary
                  fallback={
                    <p>An error has occurred. Please refresh your page.</p>
                  }
                >
                  <Redirect>
                    <UserContext>
                      {/* <div style="height:100%;"> */}
                      <PageLayout>
                        <LangProvider>
                          <Component {...pageProps} err={err} />
                        </LangProvider>
                      </PageLayout>
                      {/* </div> */}
                    </UserContext>
                  </Redirect>
                </Sentry.ErrorBoundary>
              </StyledThemeProvider>
            </AppBridgeProvider>
          </ApolloProvider>
        </ShopifyVizProvider>
      </AppProvider>
    </>
  );
};

export default App;
