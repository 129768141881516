import { useCallback } from 'react';
import { useField } from 'formik';
import { TextField } from '@shopify/polaris';

const CheckboxField = ({
  name,
  description,
  onChange,
  type = 'text',
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const { value, onBlur } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const handleChange = useCallback(
    (val) => {
      setValue(type === 'number' ? parseFloat(val) || val : val);
      if (onChange) {
        onChange(val);
      }
    },
    [setValue]
  );

  return (
    <TextField
      id={name}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={touched && error}
      helpText={description}
      autoComplete="off"
      type={type}
      {...props}
    />
  );
};

export default CheckboxField;
