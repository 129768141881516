export default [
  {
    id: 'widgets',
    content: 'Widgets',
    panelID: 'widgets-content',
    route: '/',
  },
  {
    id: 'cart',
    content: 'Upsell cart',
    panelID: 'cart-content',
    route: '/cart',
  },
  {
    id: 'analytics',
    content: 'Analytics',
    panelID: 'analytics-content',
    route: '/analytics',
  },
  {
    id: 'settings',
    content: 'Settings',
    panelID: 'settings-content',
    route: '/settings',
  },
];
