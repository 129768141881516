import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { InlineStack, BlockStack, Tabs, Divider } from '@shopify/polaris';
import styled from 'styled-components';
import { findIndex } from 'lodash';
import tabs from './tabs';

const NavBar = () => {
  const router = useRouter();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    let index = findIndex(tabs, (tab) =>
      tab.route === '/'
        ? tab.route === router.pathname
        : router.pathname.startsWith(tab.route)
    );

    if (index === -1) {
      index = 0;
    }

    if (index !== selected) {
      setSelected(index);
    }
  }, [router.pathname, selected]);

  const handleTabChange = useCallback(
    async (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      const { route } = tabs[selectedTabIndex];
      await router.push(route, route);
    },
    [router]
  );

  if (selected === -1) {
    return null;
  }

  return (
    <BlockStack>
      <InlineStack blockAlign="center">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
        <Actions>
          {/* <Button primary url="/create">
            Add widget
          </Button> */}
        </Actions>
      </InlineStack>
      <Divider />
    </BlockStack>
  );
};

const Actions = styled.div`
  position: fixed;
  top: 8px;
  right: 32px;
`;

export default NavBar;
