import { useField } from 'formik';
import { BlockStack, RadioButton } from '@shopify/polaris';

const RadioField = ({ name, options, onChange, disabled, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <BlockStack>
      {options.map(({ value, label, disabled: disabledOption }) => (
        <RadioButton
          key={value}
          id={`${name}-${value}`}
          name={name}
          label={label}
          value={value}
          checked={field.value === value}
          onChange={() => {
            helpers.setValue(value);
            if (onChange) onChange(value);
          }}
          disabled={disabledOption || disabled || false}
        />
      ))}
    </BlockStack>
  );
};

export default RadioField;
