import { useState, useCallback } from 'react';
import {
  ResourceList,
  ResourceItem,
  TextContainer,
  LegacyFilters,
} from '@shopify/polaris';

const List = ({ items }) => {
  const [queryValue, setQueryValue] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  console.log(items);

  const resourceName = {
    singular: 'customer',
    plural: 'customers',
  };

  const handleQueryValueChange = useCallback((value) => {
    setQueryValue(value);
    // setItems([]);
  }, []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const filterControl = (
    <LegacyFilters
      queryValue={queryValue}
      filters={[]}
      // appliedFilters={appliedFilters}
      onQueryChange={handleQueryValueChange}
      // onClearAll={handleClearAll}
      onQueryClear={handleQueryValueRemove}></LegacyFilters>
  );

  return (
    <ResourceList
      resourceName={resourceName}
      items={items}
      renderItem={renderItem}
      filterControl={filterControl}
      selectable
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      // promotedBulkActions={promotedBulkActions}
      // bulkActions={bulkActions}
      // resolveItemId={resolveItemIds}
    />
  );
};

const renderItem = (item) => {
  const { value, label } = item;
  return <ResourceItem id={value}>{label}</ResourceItem>;
};

export default List;
