import { useState, useCallback, cloneElement } from 'react';
import { Popover as PolarisPopover } from '@shopify/polaris';

const Popover = ({ children, content }) => {
  const [active, setActive] = useState(false);

  const toggle = useCallback(() => setActive((active) => !active), []);

  const renderActivator = useCallback(() => {
    const props = {
      onClick: toggle,
      role: 'button',
    };

    return cloneElement(children, {
      // ref: getTargetRef,
      ...props,
    });
  }, [children, toggle]);

  return (
    <PolarisPopover
      active={active}
      activator={renderActivator()}
      onClose={toggle}
      preferredPosition="below"
      preferredAlignment="center"
    >
      {content}
    </PolarisPopover>
  );
};

export default Popover;
