import { useField } from 'formik';
// import { Checkbox, TextField } from '@shopify/polaris';
import { TextInputField } from '../text-input';
import { CheckboxField } from '../checkbox';
// import Checkbox from './checkbox';

const widthValues = {
  sm: '10rem',
  md: '20rem',
  lg: '30rem',
};

const TextOptionField = ({ name, label, size = 'sm' }) => {
  const [{ value }] = useField(`${name}.enabled`);

  const textFieldMarkup = value ? (
    <div
      style={{
        paddingLeft: 'calc(var(--p-space-200) + var(--p-choice-size))',
        marginTop: '4px',
        maxWidth: widthValues[size],
      }}
    >
      <TextInputField name={`${name}.value`} />
    </div>
  ) : null;

  const checkboxMarkup = (
    <CheckboxField name={`${name}.enabled`} label={label} />
  );

  return (
    <div>
      {checkboxMarkup}
      {textFieldMarkup}
    </div>
  );
};

export default TextOptionField;
