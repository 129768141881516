import { useState, useCallback } from 'react';
import { Button, Modal, Tag, BlockStack, InlineStack } from '@shopify/polaris';
import { useQuery } from '@apollo/client';
import { COLLECTIONS } from '@/query';
import List from './list';

const CollectionPicker = ({ value, setValue }) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(value || []);
  const { data: collectionsData } = useQuery(COLLECTIONS);

  const { collections = [] } = collectionsData || {};

  const selectedCollections = collections?.filter((collection) => {
    return selected.includes(collection.shopifyId);
  });

  const collectionListMarkup = selectedCollections?.map((collection) => (
    <Tag
      onRemove={() => {
        setSelected(selected.filter((c) => c !== collection.shopifyId));
        setValue(selected.filter((c) => c !== collection.shopifyId));
      }}
      key={collection.shopifyId}
    >
      {collection.data.title}
    </Tag>
  ));

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleSave = useCallback(() => {
    if (selected.length > 0) {
      setValue(selected);
    }

    handleChange();
  }, [handleChange, setValue, selected]);

  const activator = (
    <BlockStack gap="200">
      <InlineStack>
        <Button onClick={handleChange}>
          {selected.length === 0
            ? 'Select collections'
            : `${selected.length} collection${
                selected.length > 1 ? 's' : ''
              } selected`}
        </Button>
      </InlineStack>
      <InlineStack gap="200">{collectionListMarkup}</InlineStack>
    </BlockStack>
  );

  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Select collections"
        primaryAction={{
          content: 'Save',
          onAction: handleSave,
          disabled: selected.length === 0,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        <List selected={selected} setSelected={setSelected} />
      </Modal>
    </div>
  );
};

export default CollectionPicker;
