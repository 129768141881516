import { useRef, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Icon,
  Thumbnail,
  Text,
  Button,
  TextField,
  Select,
  Checkbox,
  InlineStack,
  BlockStack,
  Divider,
} from '@shopify/polaris';
import { DragHandleMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import { VariantPicker, Flex } from '@/components/library';
import styled from 'styled-components';
import { getSizedImageUrl } from '@shopify/theme-images';

const Product = ({
  product,
  variants,
  setVariants,
  variantList,
  index,
  discounts,
  updateDiscount,
  quantity,
  updateQuantity,
  selectionEnabled = false,
  selected,
  updateSelected,
  isDeletable,
  onDelete,
  move,
  draggable,
}) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'product',
    canDrag: draggable,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [spec, drop] = useDrop({
    accept: 'product',
    hover: (item, monitor) => {
      if (!previewRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      if (dragIndex === hoverIndex) return;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      move(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      // onDragEnd();
    },
  });

  // const ref = useRef(null);
  // const dragDropRef = dragRef(dropRef(ref));

  drag(dragRef);
  drop(preview(previewRef));

  const { id } = product;
  const { title, image } = product.attributes;

  const discount = discounts && discounts.find((d) => d.id === id);
  const quantityItem = quantity && quantity.find((d) => d.id === id);

  const handleDiscountValueChange = useCallback(
    (value) =>
      updateDiscount({
        id,
        value: parseFloat(value),
      }),
    [updateDiscount]
  );

  const handleDiscountTypeChange = useCallback(
    (value) =>
      updateDiscount({
        id,
        type: value,
      }),
    [updateDiscount]
  );

  const handleQuantityChange = useCallback(
    (value) =>
      updateQuantity({
        id,
        value: parseInt(value, 10),
      }),
    [updateQuantity]
  );

  const handleSelectedChange = useCallback(
    (value) =>
      updateSelected({
        id,
        value,
      }),
    [id, updateSelected]
  );

  const handleDelete = useCallback(() => onDelete(id), [onDelete]);
  const handleVariantChange = useCallback(
    (value) => {
      const newValue = { ...variantList, [product.id]: value };
      if (value === 'all') delete newValue[product.id];
      setVariants(newValue);
    },
    [variantList, setVariants]
  );

  return (
    <Row ref={previewRef} isDragging={isDragging}>
      {draggable && (
        <div ref={dragRef} style={{ position: 'relative', cursor: 'grab' }}>
          <Icon source={DragHandleMinor} tone="base" />
        </div>
      )}
      <Flex noshrink>
        <Thumbnail
          source={image ? getSizedImageUrl(image.src, 'thumb') : ''}
          size="small"
          alt={title}
        />
      </Flex>
      <Flex grow>
        <BlockStack gap="100">
          <BlockStack>
            <Text>{title}</Text>
            {/* <Divider /> */}
          </BlockStack>
          <BlockStack>
            <InlineStack gap="200">
              <Text as="p" tone="subdued">
                {variants && variants.length > 0
                  ? `${variants.length} variants`
                  : 'All variants'}
              </Text>
              {setVariants && (
                <VariantPicker
                  product={product}
                  value={variants}
                  setValue={handleVariantChange}
                />
              )}
            </InlineStack>
            {selectionEnabled && (
              <Checkbox
                label="Preselected"
                checked={selected.find((s) => s.id === id)?.value}
                onChange={handleSelectedChange}
              />
            )}
          </BlockStack>
        </BlockStack>
      </Flex>
      {isDeletable && (
        <Button
          icon={CancelSmallMinor}
          onClick={handleDelete}
          variant="plain"
        />
      )}
      {discount && (
        <div style={{ minWidth: '140px', maxWidth: '140px' }}>
          <TextField
            type="number"
            value={discount.value}
            onChange={handleDiscountValueChange}
            // label="Discount value"
            connectedRight={
              <Select
                value={discount.type}
                onChange={handleDiscountTypeChange}
                options={[
                  { label: '%', value: 'percentage' },
                  { label: '$', value: 'fixed_amount' },
                ]}
              />
            }
          />
        </div>
      )}
      {quantityItem && (
        <TextField
          type="number"
          value={quantityItem.value}
          onChange={handleQuantityChange}
          min={1}
        />
      )}
    </Row>
  );
};

const Row = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 24px;

  &:not(:last-child) {
    border-bottom: ${(props) =>
      props.isDragging ? 'none' : `1px solid rgb(215, 219, 223)`};
  }
`;

export default Product;
