import { useField } from 'formik';
import { Select } from '@shopify/polaris';

const Field = ({ name, children, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const { value, onBlur } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  return (
    <Select
      id={name}
      name={name}
      value={value}
      onChange={setValue}
      onBlur={onBlur}
      error={touched && error}
      {...props}
    />
  );
};

export default Field;
