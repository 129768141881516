import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  InlineStack,
  BlockStack,
  Divider,
  Box,
  ResourceList,
  ResourceItem,
  Text,
  Thumbnail,
  LegacyFilters,
} from '@shopify/polaris';
import { GET_PRODUCT_LIST, PRODUCT_COUNT } from '@/query';
import { usePaginatedQuery } from '@/hooks';

const List = ({ selected, setSelected, limit, exclude }) => {
  const [query, setQuery] = useState(null);
  const productCountResult = useQuery(PRODUCT_COUNT);
  const total = productCountResult.data?.productCount ?? 0;
  const [
    { data, loading, refetch },
    { paginationMarkup, reset: resetPagination },
  ] = usePaginatedQuery(
    GET_PRODUCT_LIST,
    {
      variables: { query },
    },
    20,
    total
  );

  useEffect(() => {
    resetPagination();
    productCountResult.refetch({ query });
    refetch({ query });
  }, [query]);

  const handleQueryChange = useCallback((value) => {
    setQuery(value);
  }, []);

  const handleQueryRemove = useCallback(() => setQuery(null), []);

  const selectedIds = selected.map((item) => item.id);
  const products = data?.products || [];

  const handleSelection = (values) => {
    if (limit && values.length > limit) return;
    const existing = selected.filter((item) => values.includes(item.id));
    const newSelected = values
      .filter((item) => !selectedIds.includes(item))
      .map((id) => products.find((product) => product.id === id));
    setSelected([...existing, ...newSelected]);
  };

  const resourceName = {
    singular: 'product',
    plural: 'products',
  };

  const filterControl = (
    <LegacyFilters
      queryValue={query}
      filters={[]}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryRemove}
    />
  );

  const renderItem = useCallback((item) => {
    const { title, image } = item.attributes;

    const getImageSrc = () => {
      const url = new URL(image.src);
      url.searchParams.append('width', '40');
      return url.toString();
    };

    const media = image && (
      <Thumbnail source={getImageSrc()} size="small" alt={title} />
    );

    return (
      <ResourceItem
        id={item.id}
        key={item.id}
        media={media}
        verticalAlignment="center"
        selectable={false}
      >
        <Text variant="bodyMd" as="p" fontWeight="medium">
          {title}
        </Text>
      </ResourceItem>
    );
  }, []);

  return (
    <BlockStack>
      <ResourceList
        resourceName={resourceName}
        items={products}
        resolveItemId={(product) => product.id}
        renderItem={renderItem}
        selectedItems={selectedIds}
        onSelectionChange={handleSelection}
        filterControl={filterControl}
        selectable
        loading={loading}
        totalItemsCount={total}
      />
      <Divider />
      {paginationMarkup && (
        <InlineStack align="center">
          <Box padding="400">{paginationMarkup}</Box>
        </InlineStack>
      )}
    </BlockStack>
  );
};

export default List;
